#visualIdentityHeader {
  background: #1F1F21 url(../img/visualIdentity/header/d_Header_2560x1440px_Visual-identity.jpg) center center/ cover no-repeat;

  @include mobile {
    background-image: url(../img/visualIdentity/header/t_Header_768x1024px_Visual-identity.jpg);
  }

  @include sMobile {
    background-image: url(../img/visualIdentity/header/m_Header_750x1335px_Visual-identity.jpg);
  }
}

#visualTreatment {
  .comparePictures {
    width: 100%;
    height: 450px;
    font-size: 0;
    padding: 20px 0 80px;
    position: relative;

    div {
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      display: inline-block;
      height: 450px;
      background: url(../img/visualIdentity/compareLeft.jpg) right center no-repeat;

      &:last-child {
        background-image: url(../img/visualIdentity/compareRight.jpg);
        width: 50%;

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: -20px;
          height: calc(100% + 40px);
          width: 1px;
          background: #c7d6e2;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          left: -6px;
          bottom: -45px;
          width: 13px;
          height: 20px;
          background: url(../img/icons/comparePictureCursor.svg) center center no-repeat;
          background-size: contain;
        }
      }
    }

    @include mobile {
      height: 250px;
      padding: 20px 0;
      margin: 30px 0;
      div {
        &:last-child:after {
          bottom: -35px;
        }

        &:last-child:before {
          top: 0;
          height: calc(100% + 10px)
        }

        height: 250px;
      }
    }
  }
}

#pattern {
  .illustration {
    vertical-align: middle;
    display: inline-block;
    background: url(../img/visualIdentity/graphicIdentity.png) center center / contain no-repeat;
    width: 100%;
    height: 545px;
  }

  @include mobile {
    .illustration {
      height: 340px;
      margin-bottom: 20px;
    }
    .content > a {
      width: 100%;
      height: 350px;
      background-size: contain;
    }
  }
}

.emakigallery {
  font-size: 0;
  text-align: left;
  display: block;
  margin-right: -20px;

  li {
    width: calc(25% - 20px);
    padding-bottom: calc(25% - 20px);
    display: inline-block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    line-height: calc(20% - 20px);
    text-align: center;
    position: relative;
    margin-bottom: 25px;
    margin-right: 20px;

    @include tablet {
      width: calc(33.33% - 20px);
      padding-bottom: calc(33.33% - 20px);
    }

    @include mobile {
      width: calc(50% - 20px);
      padding-bottom: calc(50% - 20px);
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(#1c1c22, 0.2);
      z-index: 5;
      opacity: 0;
      transition: .3s opacity;
    }

    .full,
    .download {
      top: 50%;
      left: 50%;
      position: absolute;
      font-size: 0;
      display: inline-block;
      z-index: 10;
      opacity: 0;
      border-radius: 100%;
      border: none;
      width: 36px;
      height: 36px;
      transition: .3s;
      padding: 0;
      margin: 0;
      vertical-align: middle;
      cursor: pointer;
      background: #1c1c22 url(../img/icons/emakigallry.svg) right center / auto 36px no-repeat;
      background-size: 200% auto;

      &:hover {
        background-color: rgba(#1c1c22, .5);
      }

      &::after {
        display: none;
      }

      @include tablet {
        opacity: 1;
      }
      @include mobile {
        opacity: 1;
      }
    }

    button {
      transform: translateY(-50%) translateX(-18px) translateX(-26px);
    }

    .download {
      background-position: left center;
      transform: translateY(-50%) translateX(-18px) translateX(26px);
    }

    &:hover {
      &:before {
        opacity: 1;
      }

      button, a {
        opacity: 1;
      }
    }
  }

  .download:focus {
    outline: none;
  }

  .download:hover .downloads {
    opacity: 1;
    visibility: visible;
  }

  .downloads {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    background: #212529;
    min-width: 140px;
    z-index: 20;
    text-align: left;

    a {
      display: block;
      font-size: 1.3rem;
      color: white;
      line-height: 1.2em;
      padding: 10px;

      + a {
        margin-top: 5px;
      }

      &:hover {
        background: #2c3237;
      }
    }
  }
}

.images-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -8px;

  @include notMobile {
    &-container {
      max-width: 90%;
      margin-left: auto;
    }
  }

  li {
    width: calc(100% / 2);

    @include tabletPortrait {
      width: calc(100% / 4);
    }

    @include notMobile {
      width: calc(100% / 3);
    }

    @media (min-width: 1100px) {
      width: calc(100% / 4);
    }
  }

  img {
    max-width: calc(100% - 16px) !important;
    margin: 0 8px 16px !important;
  }
}

.fullSizePicture {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  opacity: 0;
  visibility: hidden;
  transition: .3s transform, .3s opacity, 0s .3s visibility, 0s .3s z-index;
  background-color: rgba(0, 0, 0, 0.9);
  transform: translateY(-50px);
  display: flex;
  padding: calc(3% + 50px) 10%;

  .container {
    flex: 1;
    display: flex;
    position: relative;
  }

  .image {
    flex: 1;
    background: black center center / cover no-repeat;
  }

  .navi {
    position: absolute;

    background: url(../img/icons/arrowGallery.svg) center center no-repeat;
    background-size: 25px auto;
    width: 40px;
    height: 30px;
    border: 0;
    font-size: 0;
    bottom: -50px;
    transition: .3s;
    outline: none;
    cursor: pointer;
    z-index: 10;

    &.next {
      right: 0;
    }

    &.previous {
      left: 0;
      transform: rotate(180deg)
    }
  }

  .infos {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px;
    background: $greyDark;
    z-index: 10;

    .name {
      color: #FFF;
      font-size: 16px;
      display: block;
    }

    .download {
      padding-bottom: 0;

      a {
        color: $blue;
      }

      &:not(:hover) ul {
        transform: translateY(15px);
      }

      > span + ul {
        top: initial;
        bottom: 100%;
        background: #212529;

        &:before {
          bottom: -7px;
          transform: rotate(180deg);
          top: initial;
        }
      }
    }
  }

  &.visible {
    visibility: visible;
    opacity: 1;
    transform: none;
    z-index: 55;
    transition: .3s transform, .3s opacity;
  }
}

// .offices-list-container {
//   @include tabletPortrait {
//     display: flex;
//     flex-wrap: wrap;
//   }

//   @include notMobile {
//     max-width: 90%;
//     margin-left: auto;
//   }

//   .offices-list {
//     text-align: left;

//     .accordion-trigger {
//       padding-left: 0;
//     }

//     .accordion-panel {
//       a.download {
//         margin-top: -10px;
//       }

//       img {
//         width: 100%;
//         max-width: 100% !important;
//         border-radius: 2px;
//       }
//     }

//     .accordion-container.active .accordion-panel {
//       padding: 0 0 20px;
//     }

//     @include tabletPortrait {
//       width: 50%;
//       padding-left: 40px;

//       &:first-child {
//         border-right: 1px solid $greyLight;
//         padding-left: 0;
//         padding-right: 40px;
//       }
//     }
//   }
// }

// .accordion {
//   &-container {

//     &.active {
//       .accordion-trigger::after {
//         transform: translateY(-7px) rotate(-180deg);
//       }

//       .accordion-panel {
//         padding: 15px 0 10px;
//         opacity: 1;
//         max-height: 500px;
//       }
//     }
//   }

//   &-trigger {
//     background-color: transparent;
//     border: 0;
//     cursor: pointer;
//     color: $greyMedium;
//     font-size: 16px;
//     font-weight: 300;
//     position: relative;
//     padding: 8px 20px 8px 8px;

//     &::after {
//       content: "";
//       height: 0;
//       width: 0;
//       position: absolute;
//       top: 50%;
//       right: 0;
//       pointer-events: none;
//       border: solid transparent;
//       border-color: rgba($greyMedium, 0);
//       border-top-color: $greyMedium;
//       border-width: 5px;
//       transition: transform .3s ease-out;
//       transform: translateY(-2px);
//     }
//   }

//   &-panel {
//     width: 100%;
//     transition: all 0.2s ease-in-out;
//     opacity: 0;
//     height: auto;
//     max-height: 0;
//     overflow: hidden;
//   }

// }
