
.fullScreenBg {
  // position  : fixed; // Safari don't handle it correctly
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  display: none;
  background: rgba(30, 29, 34, 0);
  transform: translate(-50%, -50%);

  @media (min-aspect-ratio: 16/9) {
    width: 100%;
    height: auto;
  }
  @media (max-aspect-ratio: 16/9) {
    width: auto;
    height: 100%;
  }
  @include mobile {
    display: none;
  }
}

.fluidVideo {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  + .fluidVideo {
    margin-top: 2em;
  }
}

.fluidVideo--mPadding {
	padding-bottom:66%;
}

.fluidVideo iframe,
.fluidVideo video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fluidVideo--squared {
  padding-bottom:100%;
}

.fluidVideo--wrapper__small {
  margin: 0 auto;
  width: 64%;
}

// when the video has a higher height then width add a bit margin to top
@include mobile {
	.fluidVideo--mtMobile {
		margin-top: 67px;
	}
}
