@font-face {
  font-family: 'nanum_brush_scriptregular';
  src: url('../fonts/nanumbrushscript/nanumbrushscript-regular-webfont.eot');
  src: url('../fonts/nanumbrushscript/nanumbrushscript-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nanumbrushscript/nanumbrushscript-regular-webfont.woff2') format('woff2'),
    url('../fonts/nanumbrushscript/nanumbrushscript-regular-webfont.woff') format('woff'),
    url('../fonts/nanumbrushscript/nanumbrushscript-regular-webfont.ttf') format('truetype'),
    url('../fonts/nanumbrushscript/nanumbrushscript-regular-webfont.svg#nanum_brush_scriptregular') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
	font-family: 'Amatic SC';
	src: url('../fonts/amaticsc/AmaticSC-Regular.eot');
	src: url('../fonts/amaticsc/AmaticSC-Regular.woff2') format('woff2'),
	  url('../fonts/amaticsc/AmaticSC-Regular.woff') format('woff'),
	  url('../fonts/amaticsc/AmaticSC-Regular.ttf') format('truetype'),
	  url('../fonts/amaticsc/AmaticSC-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
  }
