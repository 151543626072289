[data-page="salesComms"] {
	#communicationtoolsHeader {
		background: #1F1F21 url(../img/salesComms/header/d_Header_2560x1440px_Sales-Comms.jpg) center center/ cover no-repeat;

		@include mobile {
			background-image: url(../img/salesComms/header/t_Header_768x1024px_Sales-Comms.jpg);
			background-position: center center;

			canvas {
				display: none;
			}
		}

		@include sMobile {
			background-image: url(../img/salesComms/header/m_Header_750x1335px_Sales-Comms.jpg);
		}
	}
	.js-gallery .gallery .slide.slick-center span.presentation {
		@include tablet {
			transform: scale(1.6);
		}
		@include sMobile {
			transform: scale(1);
		}
	}
	.js-gallery .slick-arrow {
		@include largeWidth {
			bottom: -60px;
		}
		@include mediumWidth {
			bottom: 0;
		}
		@include smallWidth {
			bottom: 40px;
		}
	}
	#advertising {
		img {
			transform: scale(2);
		}
	}
	#proposal {
		img {
			transform: scale(1.2);
		}
	}
}
