#contentStyleguideHeader {
  min-height: 100vh;
  background: #1F1F21 url(../img/contentStyleGuide/header/d_Header_2560x1440px_Content-style-guide.jpg) center center/ cover no-repeat;

  @include mobile {
    background-image: url(../img/contentStyleGuide/header/t_Header_768x1024px_Content-style-guide.jpg);
    background-position: center center;

    canvas {
      display: none;
    }

    .whiteHighlight {
      top: 30%;
    }
  }

  @include sMobile {
    background-image: url(../img/contentStyleGuide/header/m_Header_750x1335px_Content-style-guide.jpg);
  }
}
