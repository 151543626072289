#container {
  animation-duration: 1.5s;
  transition-timing-function: ease-out;
  animation-fill-mode: both;
  // animation-name: showContent;
  transform-origin: 50% 50vh;
}

.secondNav {
  li {
    transition: .4s all ease-out;

    &:nth-child(1) {
      transition-delay: 0.1s;
    }

    &:nth-child(2) {
      transition-delay: 0.2s;
    }

    &:nth-child(3) {
      transition-delay: 0.3s;
    }

    &:nth-child(4) {
      transition-delay: 0.4s;
    }

    &:nth-child(5) {
      transition-delay: 0.5s;
    }

    &:nth-child(6) {
      transition-delay: 0.6s;
    }

    &:nth-child(7) {
      transition-delay: 0.7s;
    }

    &:nth-child(8) {
      transition-delay: 0.8s;
    }

    &:nth-child(9) {
      transition-delay: 0.9s;
    }

    &:nth-child(10) {
      transition-delay: 1.0s;
    }
  }
}

.is-exiting {
  #container {
    transition-timing-function: ease-out;
    // animation-name: hideContent;
  }

  .secondNav {
    // transition-timing-function: ease-out;
    // animation-name: slideRight;
    li {
      transform: translateX(50%);
      opacity: 0;
    }
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;

  .upper,
  .lower {
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    transition: .4s .3s ease-out;

    &:before {
      content: "";
      width: 100%;
      height: 15px;
      left: 0;
      bottom: 0;
      background: white;
      position: absolute;
      transform: scaleX(0);
      transition: .3s ease-out;
    }
  }

  .upper {
    transform: translateY(-100%);

    &:before {
      transform-origin: left center;
    }
  }

  .lower {
    top: 50%;
    transform: translateY(100%);

    &:before {
      top: 0;
      transform-origin: right center;
    }
  }

  .value {
    width: 100%;
    height: 6px;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    background: black;
    z-index: 10;
    transform: scaleX(0);
  }

  .loading & {
    .upper,
    .lower {
      transform: none;
      transition: .6s ease-out;

      &:before {
        transition: .3s .6s ease-out;
        transform: none;
      }
    }
  }
}

.appearance {
  #container {
    // animation-duration:0s;
  }

  .secondNav {
    li {
      // transition: 0s;
    }
  }
}

@keyframes hideContent {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes showContent {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
