#emakinationHeader {
  background: #1F1F21 url(../img/onboarding/header/d_Header_2560x1440px_Onboarding.jpg) center center/ cover no-repeat;

  @include mobile {
    background-image: url(../img/onboarding/header/t_Header_768x1024px_Onboarding.jpg);
    background-position: center center;

    canvas {
      display: none;
    }
  }

  @include sMobile {
    background-image: url(../img/onboarding/header/m_Header_750x1335px_Onboarding.jpg);
  }
}

#emailSignature {
  .signature {
    margin-top: 30px;
    font-size: 14px;

    b {
      font-weight: 700;
      margin: 15px 0 3px 0;
      display: block;
    }

    p {
      line-height: 1.1em;
      font-size: 1.4rem;

      b {
        color: $greyDark;
      }
    }

    a {
      margin-top: 3px;
      display: inline-block;
    }
  }
}

#outOfOffice {
	.slick-arrow {
    @include largeWidth {
      bottom: -60px;
    }
		@include notMobile {
			bottom: -40px;
		}
	}
	.js-gallery-one .gallery .slide.slick-center span {
		transform: scale(1);
		@include notTablet {
			transform: scale(1.4);
		}
	}
	.js-gallery-one .gallery span {
		height: 300px;
		margin-top: 10px;
	}
}

#backgrounds {
  .content.horizontal>div:first-child h2 {
    white-space: initial;
  }
  .js-gallery .gallery .slide span {
    background-size: contain;
    background-repeat: no-repeat;
  }
  .js-gallery .gallery .slide.slick-center span.presentation {
    @include largeWidth {
      transform: scale(1.2);
    }
    @include tablet {
      transform: scale(1.6);
    }
    @include sMobile {
      transform: scale(1);
    }
  }
  .slick-arrow {
    @media screen and (max-width: 1500px) {
      bottom: 36px;
    }
    @include tablet {
      bottom: 66px;
    }
  }
}
