a.button {
  font-size: 1.8rem;
  color: #616373;
  padding: 5px 20px;
  border: 1px solid rgba(#f7941d, .4);
  border-radius: 500px;
  display: inline-block;
  position: relative;
  z-index: 1;
  max-width: 500px;
  overflow: hidden;

  &:after {
    content:"";
    display: block;
    position: absolute;
    width: 550px;
    height: 550px;
    background: #f7941d;
    z-index: -1;
    border-radius: 100%;
    transform:  translate(-50%,-50%) scale(0);
    transition: .5s ease-in-out;
    top: 50%;
    left:50%;
  }

  &:hover:after {
    transform: translate(-50%,-50%)  scale(1);
  }

  @include mobile {
    font-size: 1.4rem;
    text-align: center;
    width:100%;
    padding: 10px 15px;
  }
}

a.view {
  color: $greyMedium;
  background: white;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1;
  padding: 10px 20px 10px 60px;
  margin-bottom: 8px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  z-index: 5;
  transition: .4s;
  transform: translateZ(1px);
  border:1px solid $greyMedium;
  background: url(../img/icons/viewButton.svg) no-repeat 20px center;
  background-size: 16px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:-1;
    background: $greyMedium;
    transform: scale(0,1);
    transition: .4s transform;
    transform-origin: 0 center;
  }

  &:hover {
    color: $greyLight;
    background-image: url(../img/icons/viewButtonWhite.svg);
    background-color: $greyMedium;

    &:after {
      // transform: scale(1,1);
      // transform-origin: 100% center;
    }

    &:before {
      background: #fff;
    }
  }
}

a.download {
  color: $blue;
  padding: 15px 30px;
  padding-left:0;
  font-size: 1.4rem;
  display: inline-block;
  cursor: pointer;

  &:after {
    content:"";
    display: inline-block;
    width: 13px;
    height: 11px;
    margin-left: 8px;
    vertical-align: middle;
    background: url(../img/icons/downloadArrow.svg) no-repeat center / contain;
    transition: transform .3s ease-out;
  }

  &.external {
    &::after {
      transform: rotate(-90deg);
    }

    &:hover {
      opacity: .4;

      &::after {
        transform: rotate(-90deg) translateY(5px);
      }
    }
  }

  &.locked {
    &::after {
      background-image: url(../img/icons/lockedBlue.svg);
      transform: translateY(-1px);
    }
  }
}

// Download list > dropdown
.download {
  position: relative;
  color: $blue;
  padding: 15px 30px;
  padding-left:0;
  font-size: 1.4rem;
  display: inline-block;
  z-index: 10;

  > span {
    position: relative;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    transition: .3s ease-out;

    &:after {
      content:"";
      display: inline-block;
      width: 13px;
      height: 11px;
      margin-left: 12px;
      vertical-align: middle;
      background: url(../img/icons/downloadArrow.svg) no-repeat center / contain;
    }
  }

  > span + ul {
    position: absolute;
    left:30px;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-15px);
    transition: .3s, 0s .3s visibility;
    padding: 15px 0 8px 0;
    background: $greyDark;

    &:before {
      content:"";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6.5px 7px 6.5px;
      border-color: transparent transparent #212529 transparent;
      display: inline-block;
      position: absolute;
      top:-7px;
      left: 10px;
    }

    a {
      display: block;
      color: #fefefe;
      min-width: 150px;
      padding: 10px 15px;
      transition: .1s;

      &:hover {
        background:$greyMedium;
      }
    }

    .locked a {
      position: relative;
      padding-left: 40px;

      &::before {
        content:"";
        position: absolute;
        top: auto;
        left: 15px;
        width: 13px;
        height: 12px;
        background: url(../img/icons/locked.svg)  no-repeat center / contain;
      }
    }
  }

  &:hover span {
    opacity: .4;
  }

  &:hover span + ul {
    opacity: 1;
    visibility: visible;
    transform: none;
    transition: .3s, 0s visibility;
  }

  &.locked {
    > span + ul a:before{
      content:"";
      display: inline-block;
      width: 13px;
      height: 12px;
      margin-right: 12px;
      vertical-align: middle;
      background: url(../img/icons/locked.svg)  no-repeat center / contain;
    }
  }

  &.off {
    pointer-events: none;
    opacity: 0.6;

    > span:after {
      background-image: url(../img/icons/downloadOff.svg);
    }
  }
}

