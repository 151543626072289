#openMenu {
	opacity: 0;
	position: fixed;
	top: -50px;

	+label {
		position: fixed;
		right: 60px;
		top: 50px;
		z-index: 65;
		cursor: pointer;
		transition: .6s cubic-bezier(.12, 1.05, .42, 1.24);
		height: 65px;
		overflow: hidden;
	}

	@include mobile {
		+label {
			display: none;
		}
	}
}

#openMenu:not(:checked) {
	+label {
		height: 30px;
	}
}

#main {
	transition: .3s opacity ease-out;
}

#openMenu:checked {
	~.mainNav {
		transition-timing-function: cubic-bezier(.12, 1.05, .42, 1.24);
		transform: none;
	}

	~#main {
		opacity: .3;
	}

	@include mobile {
		~.mainNav {
			transition-timing-function: ease-out;
		}
	}
}

.mainNav {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	padding: 40px 100px 20px 60px;
	transform: translateY(-100%);
	transition: .6s cubic-bezier(.29, -0.26, .93, -0.19);
	background: rgba(#0e0f10, 0.7);
	z-index: 60;
	overflow-y: scroll;

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100px;
		top: -100px;
		left: 0;
		position: absolute;
		z-index: -1;
		background: rgba(#0e0f10, 0.7);
	}

	&:after {
		content: "";
		display: block;
		clear: both;
	}

	.backHome {
		font-size: 1.4rem;
		color: $greyMedium;
		margin-bottom: 20px;
		display: block;

		&:hover {
			color: $greyMedium;
		}
	}

	.form {
		float: left;
		margin-right: 30px;


	}

	>ul {
		width: calc(100% - 270px);
		display: inline-block;
		float: left;

		>li {
			display: inline-block;
			vertical-align: top;
			margin-right: 10px;
			color: #cdcfdb;
			font-size: 14px;
			width: calc(100% / 6);
			margin-bottom: 20px;

			a {
				&:first-letter {
					text-transform: uppercase;
				}

				&.faded {
					color: $greyMedium;
					opacity: .4;

					&:hover {
						opacity: 1;
					}
				}
			}

			&.active {
				a {
					color: $greyMedium;

					&:hover {
						color: $blue;
					}

					@include mobile {
						color: $blue;

						&:before {
							content: "";
							display: inline-block;
							vertical-align: middle;
							width: 20px;
							height: 1px;
							margin-right: 10px;
							background-color: $blue;
						}
					}
				}
			}
		}
	}

	.contentSearchResults {
		position: relative;
		float: left;
		width: calc(100% - 270px);
		margin-top: 30px;
		margin-left: 260px;
		font-size: 14px;
		color: white;

		h2 {
			position: absolute;
			right: calc(100% + 40px);
			width: 260px;
			text-align: right;
			font-size: 16px;
			line-height: 1.4;
			font-weight: 700;
			color: $greyMedium;
		}

		a {
			display: flex;
			flex-wrap: wrap;
			align-items: baseline;
			margin-bottom: 10px;
			color: white;

			&:hover {
				color: white;
				opacity: .7;
			}
		}

		.resPage {
			flex-shrink: 0;
			margin-right: 1em;
			font-size: 16px;
			line-height: 1.4;
			font-weight: 700;
		}

		.resSection {
			flex-shrink: 0;
			margin-right: 1em;
			margin-left: 1em;
			font-weight: 700;
		}

		.resText {
			flex-shrink: 0;
			margin-left: 1em;
			color: $greyLight;
			line-height: 1.4;

			i {
				position: relative;

				&::after {
					content: '';
					position: absolute;
					top: 0;
					right: -2px;
					bottom: 0;
					left: -2px;
					background: $orange;
					z-index: -1;
				}
			}
		}
	}

	h3 {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 10px;
		line-height: 1.4em;

		a {
			color: white;
			vertical-align: middle;
		}
	}

	h3+ul li {
		margin: 8px 0;
	}

	a {
		color: $greyLight;
		position: relative;
		text-decoration: none;
		transition: .2s ease-out;

		&:before {
			content: none;
		}

		&:hover {
			color: $greyMedium;
		}

		&:first-letter {
			text-transform: uppercase;
		}
	}

	.col2 {
		column-count: 2;
		column-gap: 10px;
	}

	.form {
		width: 230px;

		& .searchContainer {
			position: relative;

			#searchButton {
				position: absolute;
				top: 8px;
				right: 3px;
				background: transparent url(../img/icons/search.svg) no-repeat right 12px center;
				background-size: 16px;
				width: 32px;
				height: 16px;
				outline: none;
				border: 0;
				cursor: pointer;

				@include mobile {
					display: none;
				}
			}
		}

		input {
			&#searchInput {
				width: 100%;
				background: none;
				font-size: 12px;
				border: none;
				padding: 5px 25px 5px 0;
				background-color: $greyDark;
				color: $greyLight;
				margin-bottom: 10px;
				outline: none;
				padding: 10px 17px;

				&::-webkit-input-placeholder {
					color: $greyLight;
				}

				&:-moz-placeholder {
					color: $greyLight;
				}

				&::-moz-placeholder {
					color: $greyLight;
				}

				&:-ms-input-placeholder {
					color: $greyLight;
				}
			}

			&#searchContent {
				visibility: hidden;
				width: 0;
				height: 0;
				position: absolute;
				z-index: -1;

				&+label {
					color: $greyMedium;
					font-size: 12px;
					line-height: 1.333;
					font-weight: 400;
					cursor: pointer;

					&::before {
						content: '';
						display: inline-block;
						box-sizing: border-box;
						width: 16px;
						height: 16px;
						margin-right: 10px;
						transform: translateY(4px);
						background: $greyDark;
						border: 1px solid $greyMedium;
					}
				}

				&:checked+label::before {
					background: $greyMedium;
					box-shadow: 0 0 0 2px $greyDark inset;
				}
			}
		}

		.job {
			display: none;
			font-size: 14px;
			color: #6b6b6f;
			width: 100%;
			position: relative;
			cursor: pointer;
			background: #131418;
			perspective: 600px;
			transform-style: preserve-3d;
			transform: translate3d(0, 0, 0);

			&:hover {
				.choices {
					opacity: 1;
					visibility: visible;
					transform: none;
				}
			}

			span {
				background: #131418;
				padding: 15px;
				display: block;
				position: relative;
			}

			.choices {
				top: 100%;
				left: 0;
				position: absolute;
				opacity: 0;
				transition: .3s ease-out;
				width: 100%;
				visibility: hidden;
				background: #131418;
				transform: rotateX(-50deg);
				transform-origin: left top;

				li {
					padding: 10px 15px;
				}

				li:hover {
					background: #2A2C33;
				}
			}
		}
	}

	@include tablet {
		display: flex;
		flex-direction: column;
		overflow: auto;
		max-height: 100%;
		padding: 40px 60px 20px 60px;

		.form {
			order: 1;
			width: calc(100% - 60px);
			margin-bottom: 40px;
			float: none;
		}

		>ul {
			order: 3;
			width: 100%;
			float: none;
		}

		.contentSearchResults {
			order: 2;
			width: 100%;
			float: none;
			margin: 0 0 30px;
			padding-bottom: 30px;

			h2 {
				position: static;
				width: auto;
				text-align: left;
				padding-bottom: 20px;
			}

			.resText {
				width: 100%;
			}
		}
	}

	@include mobile {
		transition-timing-function: ease-out;
		-webkit-overflow-scrolling: touch;
		background-color: #141518;
		transform: translateX(100%);
		transition: .4s ease-out;
		min-height: 100vh;
		max-height: 100vh;
		padding: 100px 15px 12vh;

		.form {
			width: 100%;
			border-bottom: 1px solid #9e9e9e;
			margin-bottom: 30px;

			input,
			label {
				display: none;
			}
		}

		>ul {
			width: 100%;
			font-size: 0;

			>li {
				margin-right: 0;
				padding-right: 10px;
				display: block;
				width: 100%;

				h3 {
					font-weight: 300;
				}

				ul {
					display: none;
				}
			}
		}

		.contentSearchResults {
			display: none;
		}
	}
}

.lateralNav {
	a {
		position: absolute;
		right: 60px;
		bottom: 30px;
		display: block;
		width: 40px;
		height: 40px;
		background: rgba($greyMedium, 0.5) url(../img/icons/simpleArrow.svg) center center no-repeat;
		background-size: 20px 15px;
		transition: .3s;
		z-index: 5;
		white-space: nowrap;

		span {
			color: #d9ddea;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: scale(.8) translate(-50%, -50%) translateY(-20px);
			opacity: 0;
			transition: .3s;
			z-index: -1;
			display: none;
		}

		&:first-child {
			transform: rotate(180deg);
			left: 60px;

			span {
				transform: scale(.8) translate(-50%, -50%) rotate(180deg) translateY(-20px);
			}

			&:hover {
				span {
					transform: scale(1) translate(-50%, -50%) rotate(180deg) translateY(-50px);
				}
			}
		}

		&:hover {
			background-color: rgba($greyLight, .1);

			span {
				opacity: 1;
				transform: scale(1) translate(-50%, -50%) translateY(-50px);
			}
		}
	}
}


/* SECOND NAVIGATION */
.secondNav {
	position: fixed;
	right: 0;
	width: 230px;
	height: 100%;
	padding-top: 130px;
	z-index: 50;

	&:before {
		content: "";
		display: block;
		height: 100%;
		width: 1px;
		background: #393947;
		left: 15px;
		top: 0;
		position: absolute;
	}

	>ul {
		padding-left: 45px;

		>li {
			margin-bottom: 20px;
		}
	}

	ul a {
		font-size: 1.4rem;
		color: $greyMedium;
		margin-bottom: 40px;
		display: inline-block;

		&:first-letter {
			text-transform: uppercase;
		}
	}

	h3 {
		color: $greyMedium;
		text-transform: capitalize;
		font-weight: 700;

		a {
			margin-bottom: 30px;
			font-size: 1.6rem;

			&.active {
				color: $greyLight;
			}
		}
	}

	>ul>li>a,
	>ul>li>ul a {
		position: relative;

		&:before {
			content: "";
			display: block;
			position: absolute;
			left: -40px;
			height: 6px;
			width: 21px;
			background: currentColor;
			top: 50%;
			margin-top: -3px;
			transition: .2s ease-out;
		}

		&:hover,
		&.active {
			color: $greyLight;

			&:before {
				width: 30px;
			}
		}
	}

	@include mobile {
		display: none;
	}
}


/* Mobile navigation */
nav.mobile {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 70px;
	z-index: 100;
	padding: 10px;
	align-items: center;
	background: transparent;
	transition: .3s background;

	h1 {
		font-size: 22px;
		color: #6f6f7d;
		opacity: 0;
		visibility: hidden;
		transition: .3s;

		+a {
			margin-left: auto;
		}
	}

	label {
		width: 35px;
		height: 26px;
		display: inline-block;
		position: relative;
		font-size: 0;
		margin-left: auto;

		&:before,
		&:after,
		span {
			width: 100%;
			height: 2px;
			background-color: #9e9e9e;
			display: block;
			position: absolute;
			left: 0;
			transition: 0.3s opacity, 0.3s transform, 0s 0.3s transform-origin;
		}

		&:after {
			content: "";
			bottom: 0;
		}

		&:before {
			content: "";
			top: 0;
		}

		span {
			top: 12px;
		}
	}

	&.visible {
		background-color: #141518;

		h1 {
			opacity: 1;
			visibility: visible;
		}
	}

	@include mobile {
		display: flex;
	}
}

#openMenu:checked~nav.mobile label {
	span {
		opacity: 0;
	}

	&:before {
		transform: rotate(45deg);
		transform-origin: 0 0;
		transition: 0.3s opacity, 0.3s transform;
	}

	&:after {
		transform: rotate(-45deg);
		transform-origin: 0% 100%;
		transition: 0.3s opacity, 0.3s transform;
	}
}
