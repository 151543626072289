[data-page="typography"] {
  #container section:last-child {
    padding-bottom: 15vh;
  }
}

#typographyHeader {
  background: #1F1F21 url(../img/typography/header/d_Header_2560x1440px_Typography.jpg) center center/ cover no-repeat;

  @include mobile {
    background-image: url(../img/typography/header/t_Header_768x1024px_Typography.jpg);
  }

  @include sMobile {
    background-image: url(../img/typography/header/m_Header_750x1335px_Typography.jpg);
  }
}

#museo {
  padding: 0;

  .content {
    z-index: 10;
    padding: 10vh calc(230px + 4vw) 0 4vw;
  }

  @include mobile {
    padding: 20px 15px;
    .content {
      padding: 0;
    }
  }
}

#museo .centered,
#script .centered {
	@include notMobile {
		align-items: center;
	}
}

#highlights {

	p {
		margin-bottom: 30px;
	}

	img {
		margin-bottom: 30px;
	}

	.small-title {
		font-size: 1.5rem;
    	font-weight: 700;
	}

	.small-text {
		font-size: 1.5rem;
		font-weight: 300;
		color: #18191d;
		margin: 10px 0 25px;
	}
}

#highlights .content div {
	text-align: left;
	position: relative;
}

