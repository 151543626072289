.fourOFourPage {
	background: #0A0B0C;
	width: 100vw;
	min-height: 100vh;

	@media screen and (max-width: 767px) {
		background-image: url(../img/404/404-guidelines.jpg);
		background-size:100%;
		background-position: center 20%;
		background-repeat: no-repeat;
	}

	object {
		min-width: 100vw;
		min-height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
		opacity: 0;
		transition: opacity .2s 1s;

		&.visible {
			opacity: 1;
		}

		@media screen and (max-width: 767px) {
			display: none;
		}
	}

	h2 {
		font-size: 3rem;
		line-height: 4.5rem;
		color: #fff;
		text-align: center;
		width: 100%;
		position : fixed;
		bottom :20%;
		left : 0;
		padding: 0 230px;

		@media screen and (max-width: 767px) {
			padding: 0;
			top: 50%;
			font-size: 2.5rem;
			line-height: 3rem;
			max-width: calc(100% - 20px);
			display: block;
			transform: translate(-50%,0);
			left: 50%;
		}
	}
}
