ul.contentList {

	li {
		position: relative;
		padding-left: 25px;
		&::before {
			content: '';
			width: 6px;
			height: 6px;
			background-color: $grayFrench;
			border-radius: 50%;
			position: absolute;
			top: 9px;
			left: 0;
		}
	}

	h4 {
		color: $orange;
		font-weight: 400;
	}

	 p {
		margin: 0;
	 }

	 &--orange {
		li {
			padding-left: 10px;
			&::before {
				background-color: $orange;
				top: 9px;
				left: 0;
			}
		}
		 p {
			margin-bottom: 20px;
		 }
	 }

	 &--skyblue {
		 li {
			 &::before {
				 background-color: rgba($color: $blue, $alpha: 0.6);
			 }
		 }
	 }
}

.mbottom {
	margin-bottom: 20px;
}

p {
	strong {
		color: $greyDark;
		font-weight: 700;
	}
}
