body,
html {
  margin: 0;
  padding:0;
  width :100%;
  font-family: $fonts;
  font-weight: 300;
  font-size: 10px;
}

body {
  background: #000;
  overflow-x:hidden;
}

body.menuOpen {
	overflow: hidden;
}

* {
  box-sizing: border-box;
}

input,
textarea,
button {
  font-family: $fonts;
}

.legend {
  max-width: 66%;
  display: inline-block;
  padding: 0 0 0 9%;
}

#container {
  position: relative;
  -moz-transition: filter .4s ease-out;
  transition: -webkit-filter .4s ease-out;
  transition: filter .4s ease-out;
  overflow-x: hidden;

  &.dimmed{
    filter: brightness(.3);
  }
}

#container.white {
  .content.horizontal > div:first-child h2,
  h2 + .download {
    color: white;
  }

  .download > span:after {
    background-image: url(../img/icons/downloadArrowWhite.svg);
  }

  .content.horizontal > div:first-child p.subtitle {
    color: white;
  }
}

header {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color:black;
  background-size: cover;
  background-position: center center;
  transform: scale(1);

  h1 {
    font-size: 4.8rem;
    width: 100%;
    text-align: center;
    left: 0;
    top: 10%;
    position: fixed;
    color: #393947;
  }

  @include mobile {
    h1 {
      top:100px;
      position: absolute;
    }
  }
}

h1.whiteHighlight {
  font-size : 7.2rem;
  // position  : fixed; // Safari don't handle it correctly
  position: absolute;
  top       :50%;
  left      : 0;
  width     : 100%;
  text-align: center;
  color     : #ffffff;
  transform: translateY(-50%);
  padding: 0 230px;

  &.dark {
    color: #000;
  }

  @include mobile {
    max-width: 100%;
    position: absolute;
    font-size: 3rem;
    padding: 0 20px;
  }
}

section {
  display: block;
  position: relative;
  width: 100%;
  padding: 10vh calc(230px + 4vw) 10vh 4vw;
  overflow: hidden;
  perspective: 800px;
  background: #fff;

  &.fullHeight {
    min-height: 100vh;

    @include mobile {
      min-height: 0;
    }
  }

  .darkBackground & {
    background: $greyDark;

    .content.horizontal > div:first-child h2 {
      color: #FFF;
    }
  }

  @include mobile {
    padding: 15px;
  }
}

.content {
  position: relative;

  &.center {
    text-align: center;
  }

  &.basic {
    h2 {
      margin: 0 0 35px 0;
    }

    p {
      font-size: 2.4rem;
      color: #989ea8;
      max-width: 700px;
      margin-bottom: 10px;

      strong {
        color: white;
      }
    }
  }

  &.horizontal {
    font-size: 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
	will-change: transform;

    > div {
      font-size: 1.4rem;
      vertical-align: middle;
    }

    > div:first-child {
      width: 33%;
      white-space: nowrap;
      vertical-align: top;
      padding-right: 5%;

      h2 {
        font-size: 3rem;
        margin-top: 10px;
        word-wrap: break-word;
        color: $greyDark;

        &:first-letter {
          text-transform: uppercase;
        }

        ~ p {
          margin-top: 30px;
        }
      }

      p {
        white-space: normal;

        a:not(.button):not(.view) {
          position: relative;

          &:before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background: currentColor;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: scale(0, 1);
            transition: .2s ease-out;
          }

          &:hover:before {
            transform: scale(1, 1);
          }
        }

        + .tips {
          margin-top: 50px;
        }
      }

      .tips {
        white-space: normal;
      }
    }

    > div:last-child {
      width: 67%;
      text-align: center;
      vertical-align: middle;

      img, object {
        max-width: 90%;
        margin: 0 auto;
        max-height: 480px;
        vertical-align: middle;
      }

      object {
        width: 90%;
      }

      &.left {
        text-align: left;

        img, object {
          max-width: 90%;
          margin: 0;
          vertical-align: middle;
        }
		.list-wrap {
			padding-top: 50px;
			&:first-of-type {
				padding-top: 25px;
			}
		}
	}

      &.js-gallery-one {
        .gallery {
          img {
            max-height: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1030px) {
    &.horizontal div:last-child object {
      max-width: 440px;
      margin: 0 auto;
    }
  }

  @include smallWidth {
    &.horizontal {
      > div:first-child {
        white-space: normal;
      }
    }
  }

  @include mobile {
    &.horizontal {
      display: block;

      > div:first-child,
      > div:last-child {
        width: 100%;
        padding: 0;
        display: block;
      }

      > div:first-child {
        white-space: initial;
        margin-bottom: 20px;
      }

      > div:last-child img {
        max-width: 100%;
        margin: 0 0 20px 0;
      }

      > div:last-child {
        &.js-gallery-one {
          .gallery {
            img {
              max-width: 420px;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

object,
img,
iframe {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #008fd4;
}

::selection {
  background: #008fd4;
}

::-moz-selection {
  background: #008fd4;
}

@include mobile {
  .lgOnly {
    display: none !important;
  }
}

@include notMobile {
  .smOnly {
    display: none !important;
  }
}

// max height none class
.mhNone {
	max-height: none !important;
}

.bottom-shadow {
	img {
		box-shadow: 0 5px 5px -5px #333;
	}
}

@include mobile  {
	.bottom-fade {
		&::before {
			height: 250px;
		}
	}
}