#colours {
  min-height: 0;
  z-index: 5;
  background: #1F1F21 url(../img/colours/header/d_Header_2560x1440px_Colours.jpg) center center/ cover no-repeat;

  @include mobile {
    min-height: 100vh;
    background-image: url(../img/colours/header/t_Header_768x1024px_Colours.jpg);
  }

  @include sMobile {
    background-image: url(../img/colours/header/m_Header_750x1335px_Colours.jpg);
  }

  ~ section {
    z-index: 10;
  }

  canvas {
    width: 100%;
    height: 100%;

    @include mobile {
      display: none;
    }
  }

  h1 {
    pointer-events: none;
  }
}


#colorScheme {
  .schemeSvg {
    margin-top: 40px;
    font-size: 0;

    .copyColor {
      width: 50%;
      vertical-align: middle;
      display: inline-block;
      padding-left: 30px;

      > div {
        display: none;
        opacity: 0;
        font-size: 0;
        transform: translateX(20px);

        &.visible {
          display: block;
        }
      }

      h4 {
        font-size: 2.4rem;
        font-weight: 500;
        margin-bottom: 25px;
      }

      p {
        display: block;
        margin: 0 0 25px 0;
        font-size: 1.8rem;
        line-height: 1.3em;
        color: $greyMedium;
      }

      .tips {
        width: 50%;
        vertical-align: top;
        display: inline-block;
        color: $greyDark;

        em {
          display: inline-block;
          margin-right: 4px;
        }
      }
    }
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    height: 500px;
    width: 50%;

    circle {
      opacity: 0.3;
      fill: none;
      stroke: #9E9E9E;
    }

    .small {
      opacity: 0.2;
    }

    line {
      opacity: 0.2;
      fill: none;
      stroke: #9E9E9E;
      stroke-width: 0.5;
    }

    text {
      cursor: pointer;
      font-size: 28px;
      transition: .3s ease-out;

      &.visible {
        font-size: 34px;
        text-decoration: underline;
      }
    }

    [color="tangerine"] {
      fill: #ff7f41;
    }

    [color="chartreuse"] {
      fill: #8cc63f;
    }

    [color="azure"] {
      fill: #008fd4;
    }

    [color="grey1"] {
      fill: #c8c8c8;
    }

    [color="grey2"] {
      fill: #969696;
    }

    [color="grey3"] {
      fill: #646464;
    }

    [color="black"] {
      fill: #565555;
    }

    [color="white"] {
      fill: #ffffff;
    }

    stop {
      transition: 0.3s ease-out;
    }

  }

  @include mobile {
    .schemeSvg .copyColor {
      width: 100%;

      .tips {
        font-size: 1.2rem;
        padding-right: 5px;

        li {
          padding-left: 15px;
        }
      }
    }

    svg {
      width: 100%;
      height: 300px;

      text {
        font-size: 45px;

        &.visible {
          font-size: 45px;
        }
      }
    }

    .schemeSvg .copyColor {
      width: 100%;

      .tips em {
        min-width: 0;
        margin-right: 10px;
      }
    }
  }
}

@keyframes switchColor {
  0% {
    background: #F7941E;
  }
  13.33%, 33.33% {
    background: #8dc63f;
  }
  46.666%, 66.666% {
    background: #008fd4;
  }
  80%, 100% {
    background: #F7941E;
  }
}

#emotional {
  .tips {
    max-width: 400px;
  }
}

#logical, #emotional {
  .left .copy {
    display: block;
    margin: 0 0 25px 0;
    font-size: 1.8rem;
    line-height: 1.3em;
    color: #91919d;
  }
}
