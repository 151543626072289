#emakinaBrandHeader {
  background: #1F1F21 url(../img/emakinaBrand/header/d_Header_2560x1440px_Brand.jpg) center center/ cover no-repeat;

  canvas {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @include mobile {
    background-image: url(../img/emakinaBrand/header/t_Header_768x1024px_Brand.jpg);
  }

  @include sMobile {
    background-image: url(../img/emakinaBrand/header/m_Header_750x1335px_Brand.jpg);
  }
}

.illu {
  display: block;
  max-width: 650px;
  height: 250px;

  @include largeHeight {
    height: 350px;
  }
}

.baseline-example {
  font-size: 40px;
  font-weight: 500;
}

#safeArea object {
  height: 406px;
}

.incorrectExamples {
  font-size: 0;

  li {
    display: inline-block;
    vertical-align: top;
    width: 180px;
    text-align: left;
    margin: 0 30px 30px 30px;
  }

  img, object {
    display: block;
    width: 180px;
    height: 180px;
    max-width: 100% !important;
    border: 1px solid rgba($greyLight, 0.3);
  }

  span {
    color: $greyMedium;
    font-size: 1.4rem;
    line-height: 1.2em;
    opacity: .7;
    margin-top: 10px;
    display: block;
    text-align: center;
  }

  @include mobile {
    text-align: left;
    li {
      width: 100%;
      margin: 0;
      padding: 0 30px 30px;
      text-align: center;

      img, object {
        max-width: 100%;
        display: inline-block;
        margin: 0 !important;
        width: auto !important;
        border: none;
      }

      span {
        padding: 0 40px;
      }
    }
  }

  @include tabletPortrait {
    li {
      width: 50%;
    }
  }

  @include notMobile {
    li {
      width: 180px;
    }
  }
}


#ownership {
	padding-bottom: 15vh;
	.centered {
		align-items: center;
	}
}
