/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }

  @include mobile {
    padding-bottom: 40px !important;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline:none;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  &:focus {
    outline: none;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.mobileSlider {
  .slick-arrow {
    position: absolute;
    border: none;
    background: url(../img/icons/arrowGalleryGrey.svg) center center / 25px auto no-repeat;
    font-size :0;
    width: 40px;
    height: 30px;
    padding: 0;
    outline:none;
    transition : .3s;
    z-index: 50;
    bottom:10px;
    right: 30%;

    &.slick-disabled {
      opacity: 0;
    }

    &.slick-prev {
      transform: rotate(180deg);
      left:30%;
    }
  }

  .slick-list {
    object {
      pointer-events: none;
    }
  }
}

.js-gallery,
.js-gallery-one {
  .gallery {
    font-size: 0;
    cursor: pointer;

    &:not(.slick-initialized) {
      max-height: 380px;
      overflow: hidden;
    }

    .slide {
      width: 20%;
      display: inline-block;
      padding: 50px 0 80px;
      z-index: 40;
      position: relative;
      opacity: .5;
      transition: opacity .4s ease-out;

      &:focus {
        outline: none;
      }

      &.slick-center {
        z-index: 50;
        opacity: 1;

        span {
          transform: scale(1.2);
        }

        span.presentation {
          transform: scale(1.6);
        }

        em {
          opacity: 1;
          transform: translateY(0);
          min-height: 23px;
        }

        @include sMobile {
          span.presentation {
            transform: scale(1.2);
          }
        }
      }

      @include sMobile {
        padding: 20px 0;
      }
    }

    span {
      width: 100%;
      display: inline-block;
      height: 250px;
      background-size: cover;
      background-position: center center;
      transform: scale(1);
      transition: .4s ease-out;
    }

    span.presentation {
      background-size: contain;
      background-repeat: no-repeat;
    }

    em {
      font-size: 1.4rem;
      display: block;
      color: $greyMedium;
      text-align: center;
      opacity: 0;
      transition: .4s ease-out;
      width: 70%;
      left: 15%;
      bottom: 0;
      position: absolute;
      margin: 0 auto;
      transform: translateY(-100px);
    }
  }

  .slick-arrow {
    background: url(../img/icons/arrowGallery.svg) center center no-repeat;
    background-size: 25px auto;
    width: 40px;
    height: 30px;
    border: 0;
    font-size: 0;
    position: absolute;
    bottom: 0;
    right: 30%;
    transition: .3s;
    outline: none;
    cursor: pointer;
    z-index: 10;

    &:hover {
      transform: translateX(15px);
    }

    &.slick-prev {
      left: 30%;
      transform-origin: center center;
      transform: rotate(180deg);

      &:hover {
        transform: rotate(180deg) translateX(15px);
      }

      @include sMobile {
        left: 0;
      }
    }

    @media screen and (max-width: 1500px) {
      bottom: 66px;
    }

    @include sMobile {
      right: 0;
      margin: auto 0;
      top: 0;
      bottom: 0;
    }

    @media screen and (max-width: 500px) {
      background-color: $black;
    }
  }

  &.theme-dark {
    .slick-arrow {
      background-image: url(../img/icons/arrowGalleryGreySmooth.svg);
    }
  }

  &.theme-light {
    .slick-arrow {
      background-image: url(../img/icons/arrowGallery.svg);
    }
  }

  @include mobile {
    .gallery em {
      font-size: 1rem;
    }
  }
}

.js-gallery-one {
  .gallery {
    cursor: default;

    .slide {
      opacity: 1;
    }

    .slide.slick-center {
      em {
        width: 25%;
        left: 50%;
        transform: translate(-50%, 0%);
        font-size: 19px;
        color: #c8c8c8;
      }
    }
  }
}

.slick-section {
  .slide-button {
    border: none;
    font-size: 3rem;
    color: $greyMedium;
    font-weight: 100;
    background: none;
    display: block;
    text-align: center;
    max-width: 300px;
    margin: 30px auto 0 auto;
    position: relative;
    width: 100%;
    cursor: pointer;

    &:focus, &:target {
      box-shadow: none;
      outline: 0;
    }

    &:before, &:after {
      content: '';
      display: block;
      width: 40px;
      height: 100%;
      background-image: url(../img/icons/arrowGalleryGreySmooth.svg);
      background-size: contain;
      background-position: center;
      position: absolute;
      background-repeat: no-repeat;
    }

    &:after {
      top: 0;
      right: 0;

    }

    &:before {
      top: 0;
      left: 0;
      transform: rotate(180deg);
    }
  }

  .snav {
    position: relative;
    text-align: center;
    max-width: 300px;
    margin: 30px auto 0 auto;
    font-size: 3rem;
    color: $greyMedium;
    font-weight: 100;
    text-align: center;
  }

  .sprev, .snext {
    width: 40px;
    height: 26px;
    background: url(../img/icons/arrowGalleryGreySmooth.svg) center no-repeat;
    background-size: contain;
    position: absolute;
    top: 4px;
    right: 0;
    cursor: pointer;
  }

  .sprev {
    transform: rotate(180deg);
    right: auto;
    left: 0;
  }
}

.slick-section-small {
  .snav {
    font-size: 2rem;
  }

  .sprev, .snext {
    width: 26px;
    top: -2px;
  }
}
