#brandHeader {
  background: #000000;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
  }

  @include mobile {
    background: url(../img/subBrand/header/t_Header_768x1024px_Brand-architecture.jpg) center center no-repeat;
    background-size: cover;

    canvas {
      display: none;
    }
  }

  @include sMobile {
    background-image: url(../img/subBrand/header/m_Header_750x1335px_Brand-architecture.jpg);
  }
}

.brandEmakina {
  object#group {
    height: 159px;
  }

  object#entities,
  object#division,
  object#partnerShips {
    height: 335px;
  }
}
