$fonts : "museo-sans", Verdana, sans-serif;
$fontAmatic: 'Amatic SC';
$fontNanum : 'nanum_brush_scriptregular', cursive;

$blue : #008fd4;
$orange : #ff7f41;
$green : #70ae3c;

$greyLight : #cdcfdb;
$greyMedium : #91919d;
$greyDark : #18191d;
$grayFrench : #bfbfc5;

$white: #fff;
$black: #000;

@mixin smallHeight {
  @media (max-height: 800px){
    @content;
  }
}

@mixin largeHeight {
  @media (min-height: 1000px){
    @content;
  }
}

@mixin sMobile {
	@media (max-width: 760px) {
		@content;
	}
}

@mixin mobile {
  @media (max-width: 960px){
    @content;
  }
}
@mixin notMobile {
  @media (min-width: 960px){
    @content;
  }
}

@mixin tabletPortrait {
  @media (min-width: 768px){
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1100px){
    @content;
  }
}

@mixin notTablet {
	@media (min-width: 1100px) {
		@content;
	}
}

@mixin smallWidth {
  @media (max-width: 1600px){
    @content;
  }
}

@mixin mediumWidth {
	@media (max-width: 2000px){
		@content;
	}
}

@mixin largeWidth {
  @media (min-width: 2000px){
    @content;
  }
}
