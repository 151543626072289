[data-page="communicationtools"] {
  #communicationtoolsHeader {
    background: #1F1F21 url('../img/communicationtools/communicationtoolsHeader.jpg') center / cover;
  }
  // code commented
  #cardboard {
    .slide-button {
      border: none;
      font-size: 2rem;
      color: $greyMedium;
      font-weight: 100;
      background: none;
      display: block;
      text-align: center;
      max-width: 300px;
      margin: 30px auto 0 auto;
      position: relative;
      width: 100%;
      cursor: pointer;

      &:focus, &:target {
        box-shadow: none;
        outline: 0;
      }

      &:before, &:after {
        content: '';
        display: block;
        width: 40px;
        height: 100%;
        background-image: url(../img/icons/arrowGalleryGreySmooth.svg);
        background-size: contain;
        background-position: center;
        position: absolute;
        background-repeat: no-repeat;
      }

      &:after {
        top: 0;
        right: 0;

      }

      &:before {
        top: 0;
        left: 0;
        transform: rotate(180deg);
      }

    }
  }
}
