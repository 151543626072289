#iconsHeader {
  background: #1F1F21 url(../img/icons/header/d_Header_2560x1440px_Icons.jpg) center center/ cover no-repeat;

  canvas {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @include mobile {
    background-image: url(../img/icons/header/t_Header_768x1024px_Icons.jpg);
    background-position: center center;

    canvas {
      display: none;
    }

    .whiteHighlight {
      top: 30%;
    }
  }

  @include sMobile {
    background-image: url(../img/icons/header/m_Header_750x1335px_Icons.jpg);

    .whiteHighlight {
      top: 40%;
    }
  }
}

.icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include tabletPortrait {
    justify-content: flex-start;
    margin: 0 -25px;
  }

  li {
    width: calc(50% - 10px);
    margin-bottom: 20px;

    @include tabletPortrait {
      width: calc(100% / 5);
      padding: 0 25px;
    }

    @include largeWidth {
      padding: 0 45px;
    }

    svg {
      width: auto;
      max-width: 100%;
    }

    p {
      font-size: 13px;
      color: $greyMedium;
      text-transform: uppercase;
      font-weight: 100;
      line-height: 1.4;
      margin-top: 10px;
      @include mobile {
        font-size: 12px;
      }
    }
  }
}
