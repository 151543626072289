.section-title {
  font-size: 40px;
  margin: 20px 0 60px;
}

.content {
  h1 {
    font-size: 3.6rem;
    color: #393947;
    width: 100%;
    text-align: right;
    margin-bottom: 160px;

    @include mobile {
      font-size: 3rem;
      text-align: left;
      color: #ffffff;
      margin-bottom: 40px;
    }
  }

  h2 {
    font-size: 1.8rem;
    max-width: 650px;
    display: block;
    vertical-align: middle;
  }

  h3 {
    font-size: 2.2rem;
	color: $greyDark;
  }

  h4 {
	font-size: 1.8rem;
	line-height: 1.5em;
  }
}

p {
  color:$greyMedium;
  font-size: 1.8rem;
  line-height: 1.5em;
  font-weight: 100;
  margin: 20px 0;

  @include mobile {
    font-size: 1.5rem;
  }
}

.list-light {
  color: $grayFrench;

  li {
    position: relative;
    padding: 0 0 0 20px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      background: $grayFrench;
      top: 4px;
      left: 0;
    }
  }
}

.tips {
  font-size: 1.4rem;
  font-weight: 100;
  color: $greyDark;

  strong {
    display: inline-block;
    margin-bottom: 20px;
    font-weight: 700;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  p {
    font-size: 1.5rem;
    font-weight: 300;
    color: $greyDark;
    margin: 10px 0 25px;
  }

  &-big {
    list-style: disc;
    margin-left: 1em;
    color: $greyMedium;
    font-size: 1.8rem;
    font-weight: 100;
    line-height: 1.8rem;

    @include mobile {
      font-size: 1.5rem;
    }

    li {
      margin-bottom: 10px;
    }
  }
}

.tips,
ul.square {
  li {
    margin-bottom: 10px;
    position: relative;
    line-height: 1.8rem;
  }
}
