#emakinaMotionHeader {
  background: #1F1F21 url(../img/motion/header/d_Header_2560x1440px_Motion.jpg) center center/ cover no-repeat;

  @include mobile {
    background-image: url(../img/motion/header/t_Header_768x1024px_Motion.jpg);
  }

  @include sMobile {
    background-image: url(../img/motion/header/m_Header_750x1335px_Motion.jpg);
  }
}

[data-page="motion"] section {
  iframe {
    padding: 17px;
    border-radius: 2px;
  }
  .left {
	  text-align: left;
  }
}
