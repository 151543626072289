#printMaterial,
#merchandising {
  min-height: 100vh;

  .floatingItems {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1F1F21;

    object {
      pointer-events: none;
      width: 100%;
      height: 100%;
    }

    @include mobile {
      background: url(../img/merchandise/header/t_Header_768x1024px_Merchandise.jpg) center center/ cover no-repeat;


      object {
        display: none;
      }
    }

    @include sMobile {
      background-image: url(../img/merchandise/header/m_Header_750x1335px_Merchandise.jpg);
    }
  }
}

.products-list {
  display: flex !important;
  flex-flow: row wrap;
  margin-top: 5rem;

  @include mobile {
    display: flex;
  }

  .product {
    width: calc(100% / 3 - 1.6rem);
    margin-right: 1.6rem;
    margin-bottom: 2rem;
    box-shadow: 0 0 10px rgba($greyMedium, 0.3);
    transition: 0.3s;
    padding: 2rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: relative;

    @include tablet {
      width: calc(100% / 2 - 1.6rem);
    }

    &:hover {
      box-shadow: 0 0 20px rgba($greyMedium, 0.5);
      transition: 0.3s;
    }

    h3 {
      text-transform: uppercase;
      font-family: $fonts;
      font-weight: bold;
      color: $greyMedium;
      font-size: 1.6rem;
      margin-top: auto;
    }

    img {
      margin-bottom: 2rem;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
