#social {
  background-color: transparent;
}

#socialHeader {
  background: #1F1F21 url(../img/socialMedia/header/d_Header_2560x1440px_SoMe.jpg) center center/ cover;

  @include mobile {
    background-image: url(../img/socialMedia/header/t_Header_768x1024px_SoMe.jpg);
  }

  @include sMobile {
    background-image: url(../img/socialMedia/header/m_Header_750x1335px_SoMe.jpg);
  }

  h1 {
    margin-left: -115px;

    @include mobile {
      margin-left: 0;
    }
  }
}

.mrgT {
  margin-top: 50px;
}

#bios {
	.js-gallery-one .gallery .slide {
		padding: 0 0 4px !important;
	}
	.js-gallery-one .gallery .slide.slick-center span.presentation  {
		transform: scale(1);
		min-height: 791px;
		@include mobile {
			min-height: 550px;
		}
	}
	.js-gallery-one .gallery .slide.slick-center span.thumb  {
		font-family: $fonts;
		font-size: 22px;
		color: $greyDark;
		height: 100%;
		display: inline;
	}
	.slick-arrow {
		bottom: 0 !important;
	}
	.slick-list {
		@include mobile {
			padding: 0 40px !important;
		}
	}
}

#stories {
	.js-gallery .gallery span.thumb  {
		display: none;
		font-family: $fonts;
		font-size: 22px;
		color: $greyDark;
		height: 100%;
	}

	.js-gallery .gallery .slide.slick-center span.thumb {
		display: inline;
	}

	.js-gallery .gallery .slide.slick-center span.presentation {
		margin-bottom: 175px;

		@include smallWidth {
			margin-bottom: 150px;
		}

		@include tablet {
			margin-bottom: 90px;
		}

	}

	.js-gallery .gallery .slide {
		padding: 50px 0 5px;

		@include smallWidth {
			padding: 50px 0 70px;
		}

		@include mobile {
			padding: 50px 0 32px;
		}
	}

	.js-gallery .gallery span {
		@include notMobile {
			min-height: 500px;
		}
	}
}

/*
.typing-animation {
  width: 70%;
  position: absolute;
  top: 125px;
  left: 50%;
  transform: translate(-50%, 0%);

  .inner {
    margin-right: 230px;
  }

  ul {
    margin: 0 20px 20px 20px;

    li {
      overflow: hidden;
      opacity: 0;
      clear: both;
      margin: 0 0 20px 0;
      position: relative;
      padding-left: 60px;

      .ico {
        display: inline-block;

        &:after {
          content: "";
          background: url(../img/socialMedia/emoticones.png) 0px -76px;
          display: inline-block;
          width: 28px;
          height: 28px;
          margin: 0 0 -5px 0;
        }

        &.rock {
          &:after {
            background-position: 0px -112px;
          }
        }

        &.like {
          &:after {
            background-position: 0px -45px;
          }
        }
      }

      .icon {
        display: block;
        vertical-align: top;
        border: 1px solid #dbdbdb;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 2px;
        left: 0;

        &:before {
          content: "";
          background: url(../img/socialMedia/logo.svg);
          display: inline-block;
          width: 11px;
          height: 24px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      div {
        position: relative;
        background: #f1eff0;
        display: inline-block;
        padding: 10px;
        border-radius: 5px;
        max-width: 280px;

        &:after {
          right: 100%;
          top: 14px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(241, 239, 240, 0);
          border-right-color: #f1eff0;
          border-width: 7px;
        }
      }

      p {
        // white-space: nowrap;
        overflow: hidden;
        width: 100%;
        color: #393947;
        font-size: 18px;
        line-height: 25px;
        margin: 15px 0;
      }

      &.active {
        opacity: 1;
        transition: opacity 1.5s ease-in-out;
        animation: bounce 0.9s ease;
      }

      &.answer {
        padding-left: 0;
        padding-right: 60px;
        float: right;

        &.active {
          animation: bounce2 0.9s ease;
        }

        .icon {
          left: auto;
          right: 0;

          &:before {
            content: "";
            background: url(../img/socialMedia/emoticones.png);
            display: inline-block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        div {
          background: #1787fb;

          &:before {
            right: auto;
            left: 99.8%;
            top: 14px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &:after {
            display: none;
          }

          &:before {
            // border-right-color: none;
            border-left-color: #1787fb;
            border-width: 7px;
          }
        }

        p {
          color: #fff;
        }
      }
    }
  }

  @include mobile {
    width: 95%;

    .inner {
      margin-right: 0;
    }

    ul {
      li {
        div {
          max-width: 100%;
        }
      }
    }
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes bounce2 {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
*/

#covers {
  .js-gallery .gallery span {
    min-height: 330px;
  }
}

// .img-grid-container {
//   margin-bottom: 50px;

//   @include notMobile {
//     margin-bottom: 80px;
//   }

//   &:last-child {
//     margin-bottom: 0;
//   }

//   h3 + p {
//     font-size: 1.4rem;
//     margin: 15px 0 40px;

//     @include notMobile {
//       max-width: 80%;
//     }
//   }

//   .img-grid {
//     [class^="img-"] {
//       margin-bottom: 20px;
//     }

//     [class^="img-"] {
//       margin-bottom: 0;
//     }

//     div {
//       display: none;

//       &:first-child {
//         display: block;

//         img,
//         video {
//           width: 100%;
//           height: auto;
//           max-width: none !important;
//           max-height: none !important;
//         }
//       }
//     }

//     @include tabletPortrait {
//       display: flex;
//       justify-content: space-between;

//       [class^="img-"] {
//         margin-bottom: 0;
//       }

//       div {
//         display: block;
//       }

//       .ratio {
//         position: relative;
//         overflow: hidden;

//         img,
//         video {
//           position: absolute;
//           top: 50%;
//           left: 50%;
//           transform: translate(-50%, -50%);
//           width: 100%;
//           height: auto;
//           min-height: 100%;
//           max-width: none !important;
//         }
//       }

//       .img-1-3 {
//         width: calc((100% / 3) - 10px);
//         margin-left: 16px;

//         &:first-child {
//           margin-left: 0;
//         }

//         .ratio {
//           padding-bottom: 100%;
//         }

//         + .img-2-3 {
//           margin-left: 16px;
//         }
//       }

//       .img-2-3 {
//         width: calc(((100% / 3) * 2) - 50px);
//         margin-right: auto;

//         .ratio {
//           height: 100%;
//         }
//       }
//     }
//   }
// }
