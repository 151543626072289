#stationeryHeader {
  background: #1F1F21 url(../img/stationery/header/d_Header_2560x1440px_Stationery.jpg) center center/ cover;

  @include mobile {
    background-image: url(../img/stationery/header/t_Header_768x1024px_Stationery.jpg);
  }

  @include sMobile {
    background-image: url(../img/stationery/header/m_Header_750x1335px_Stationery.jpg);
  }
}
