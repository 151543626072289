.homeScreen {
	position: relative;
	background : #000000 url(../img/home/newPatternBg.svg) center center;
	background-size: 3036px auto;
	background-repeat: no-repeat;
	// background-attachment: fixed;
	perspective: none;

	&.fullHeight {
		min-height: 100vh;
	}

	object {
		width: 3036px;
		height: auto;
		// position: fixed;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		max-width: none;
		pointer-events: none;
	}

	h1 {
		position: absolute;
		color: $greyMedium;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		.homeLogo {
			opacity: 0;
		}

		.additional {
			position: absolute;
			left:0;
			width:100%;
			text-align: center;
			top:calc(100% + 30px);
			font-size: 20px;
			display: block;
			opacity: 0;
			font-weight: 300;
			color: $white;
			letter-spacing: 2px;
		}

		strong {
			position: absolute;
			left:0;
			width:100%;
			text-align: center;
			top:calc(100% + 80px);
			font-size: 24px;
			display: block;
			opacity: 0;
			font-weight: 100;
			color: $greyMedium;
		}
	}

	.scroll-down {
		$strokeWidth: 3px;
		$strokeColor: white;

		$timing: 2.6s;
		$easing: cubic-bezier(0.77, 0, 0.175, 1);

		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		padding-bottom: 85px;
		display: flex;
		flex-direction: column;
		align-items: center;
		opacity: 0;

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			height: 65px;
			width: $strokeWidth;
			background-color: $strokeColor;
		}

		.scroll-test {
			margin-bottom: 20px;

			&::before,
			&::after {
				content: '';
				display: block;
				width: 10px;
				height: 10px;
				transform: rotate(45deg);
				border-bottom: $strokeWidth solid $strokeColor;
				border-right: $strokeWidth solid $strokeColor;
			}

			&::before {
				animation: down-arrow-before $timing $easing infinite;
			}

			&::after {
				animation: down-arrow-after $timing $easing infinite;
			}
		}

		span {
			color: $greyMedium;
			font-size: 11px;
			font-weight: 200;
			letter-spacing: 1px;
			text-transform: uppercase;
		}

		@keyframes down-arrow-before {
			50% {
				transform: rotate(45deg) translate(70%, 70%);
			}
			100% {
				transform: rotate(45deg) translate(70%, 70%);
			}
		}

		@keyframes down-arrow-after {
			50% {
				transform: rotate(45deg) translate(110%, 110%);
				opacity: 0;
			}
			51% {
				transform: rotate(45deg) translate(-130%, -130%);
			}
			100% {
				transform: rotate(45deg) translate(-70%, -70%);
				opacity: 1;
			}
		}
	}

	@include mobile {
		background-attachment: initial;

		h1 {
			text-align: center;
			top: 40%;

			.homeLogo {
				opacity: 1;
			}

			strong {
				opacity: 1;
			}
		}

		object {
			position: absolute;
		}
	}
}

.homeScreen ~ .welcome {
	background     :black;
	background-size: auto 60%;
	color          : $greyMedium;
	padding        : 10vh 4vw;
	display        : none;

	&[country='fr']:before {
		background-image: url(../img/home/manuel.png);
	}

	&:before {
		content:"";
		opacity: .7;
		display: block;
		position: absolute;
		width: calc(50% - 4vw);
		height: calc(100% - 10vh);
		right: 4vw;
		bottom: 0;
		background-size: contain;
	}

	.leftContent {
		width: 50%;
		padding-left: 10%;
		position:absolute;
		top:50%;
		left:0;
		transform: translateY(-50%);

		h2 {
			font-size: 22px;
			font-weight: bold;
			margin-bottom: 40px;
		}

		p {
			font-size: 18px;
			line-height: 1.5;
			width: 650px;
			max-width: 30vw;
			margin-bottom: 20px;
		}
	}

	.signature {
		font-size: 18px;
		display: inline-block;
		margin-top: 100px;

		svg {
			width: 298px;
			height: 75px;
			display: block;
			position: relative;
			left: -15px;
			margin-bottom: 15px;
		}

		&.visible path {
				transition :.6s;
		}

		&.visible path:nth-child(1) {transition:.6s ease-in-out;}
		&.visible path:nth-child(2) {transition:.4s 0.6s linear;}
		&.visible path:nth-child(3) {transition: .4s 1s;}
		&.visible path:nth-child(4) {transition-delay:1.5s;}
		&.visible path:nth-child(5) {transition-delay:2.1s;}
		&.visible path:nth-child(6) {transition-delay:2.7s;transition-duration: .4s;}
		&.visible path:nth-child(7) {transition-delay:3.1s;}
		&.visible path {
				stroke-dashoffset:0;
		}

		path {
				transition: .4s;
		}

		span {
			display: block;
			margin-top: 10px;
			font-size: 0.8em;
		}
	}

	@include mobile {
		&:before {
			height: 250px;
			background-position: center bottom;
		}

		.leftContent {
			width: 100%;
			position: relative;
			left:0;
			transform: none;
			padding: 0;

			h2,
			p,
			.signature {
				opacity: 1 !important;
				transform: none !important;
			}

			p {
				font-size: 1.4em;
				width: auto;
				max-width: none;
			}
		}

		.signature {
			margin: 0;
			font-size: 16px;
			max-width: 60%;

			svg {
				max-width: 100%;
				left: 0;
				margin: 0;
			}

			path {
				stroke-dashoffset: 0;
			}
		}
	}
}

#aboutUs {
	padding: 5vh 4vw;
	min-height: 100vh;
	font-size:0;
	overflow: hidden;
	background: url(../img/home/background_mountain.jpg) no-repeat center bottom / cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;

	.particles {
		&:after {
			content:"";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			background: rgba($greyDark, 0.7);
			opacity: 0;
			pointer-events: none;
			width: 100%;
			height: 100%;
			transition: 1s 1s ease-in-out;
		}

		div {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;
			transform: translate3d(0,0,0) scale(0.9);

			&:nth-child(1) {
				background-image: url(../img/home/partials/perso.png);
				background-size: contain;
				width: 15%;
				height: 80%;
				top: 20%;
				left: 42.5%;
				transform: translate3d(0,0,1px) scale(0.9);
			}
			&:nth-child(2) {background-image: url(../img/home/partials/sand_1.png);}
			&:nth-child(3) {background-image: url(../img/home/partials/sand_2.png);}
			&:nth-child(4) {background-image: url(../img/home/partials/sand_3.png);}
			&:nth-child(5) {background-image: url(../img/home/partials/sand_4.png);}
			&:nth-child(6) {background-image: url(../img/home/partials/guy.png);}
		}
	}

	.mission {
		font-size: 0;
		width: 100%;
		max-width: 600px;
		margin: 0 auto 50px;
		position: relative;
		text-align: center;
		color: $greyLight;
		opacity: 0;
	}

	h3 {
		font-size: 4.2rem;
		margin: 0 0 0.2em;
		font-weight: 400;
	}

	p {
		font-weight: 100;
		font-size: 2.4rem;
		line-height: 1.5;

		+ h3 {
			margin-top: 0.8em;
		}
	}

	.start {
		text-align: center;
		width: 100%;
		opacity: 0;
	}

	label{
		@extend a.view;
		cursor: pointer;
		font-weight: 500;
		padding-top: 15px;
		padding-bottom: 15px;
		border: 0px;
	}

	&.visible{
		.particles:after{
			opacity: 1;
		}
	}
}
